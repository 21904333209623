function isIgnored(inputName) {
  return inputName && inputName.substring(0,1) == "_";
}

jQuery.fn.serializeRailsArray = function(modelName) {
  var serialized = jQuery(this).serializeArray();
  var obj = {};
  var form = jQuery(this);
  jQuery(serialized).each(function() {
    var element = form.find("*[name='"+this.name+"']");

    if (this.name.indexOf(modelName) != -1) {
      if(isIgnored(this.name)) return;

      var attr_name = this.name.substring(modelName.length + 1, this.name.length - 1)

      if (!element.hasClass('hasDatepicker')) {
        obj[attr_name] = this.value;
      } else {
        var date = $(element).datepicker('getDate');
        if (data !=null && date instanceof Date)
          obj[attr_name] = date.format(Helpers.SERVER_DATE_FORMAT);
      }

    } else {
      obj[this.name] = this.value;
    }
  });

  return obj;
};
